import React, { useEffect, useState } from 'react';
import './styles/css/slate.css';

function Slate() {
    const [isHidden, setIsHidden] = useState(false);

    useEffect(() => {
        const checkTimeAndDay = () => {
            const now = new Date();
            const hours = now.getHours();
            const day = now.getDay();

            if (day === 6 || day === 0) {
                setIsHidden(true);
            } else {
                if (hours >= 9 && hours < 16) {
                    setIsHidden(false);
                } else {
                    setIsHidden(true);
                }
            }
        };

        checkTimeAndDay();

        const intervalId = setInterval(checkTimeAndDay, 60000);

        return () => clearInterval(intervalId);
    }, []);

    return (
        <div className={`slate ${isHidden ? 'hidden' : ''}`}>
            <h3>PLAT DU JOUR&thinsp;:<br />Blanquette de veau, riz basmati</h3>
        </div>
    );
}

export default Slate;
